import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Avatar,
  Button,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Typography
} from 'antd';
import { debounce, map, trim, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import deleteIcon from '../../../assets/images/delete-icon.png';
import defaultIcon from '../../../assets/svg/default-app.svg';
import {
  ACTIONS,
  LIMIT,
  MODAL_WIDTH,
  ROLE_KEYS
} from '../../../common/constants';
import { avatarColor, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import {
  DELETE_MEMBER,
  EDIT_MEMBER,
  INVITE_GROUP_MEMBER,
  INVITE_MEMBER_IN_TEAM
} from '../graphql/Mutation';
import { GET_GROUP_LIST } from '../graphql/Queries';
import DeleteMemberModal from './DeleteMemberModal';

const { TabPane } = Tabs;

const { Option } = Select;
const { Text } = Typography;
const { required, checkEmailArray } = formValidatorRules;

let groupScrollDebounceJob;

const MembersModal = ({
  handleVisible,
  memberModal,
  isEdit,
  setIsEdit,
  record,
  memberLists,
  setIsListEnd,
  setListLoader
}) => {
  const {
    state: { teamId }
  } = useContext(AppContext);

  const groupList = {
    teamId,
    skip: 0,
    limit: LIMIT
  };

  const [form] = Form?.useForm();

  const [deleteMemberModalVisible, setDeleteMemberModalVisible] = useState(
    false
  );
  const initialValue = {
    name: trim(`${record?.firstName} ${record?.lastName}`),
    email: record?.invitedUserEmail,
    role: record?.role,
    groups: map(record?.groupDetails, (item) => item?.id)
  };

  const [loading, setLoading] = useState(false);
  const [isResendInvitation, setIsResendInvitation] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isEditMember, setIsEditMember] = useState(true);
  const [groupsData, setGroupsData] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [groupSearchFlag, setGroupSearchFlag] = useState(false);
  const [debounceCall, setDebounceCall] = useState(0);
  const [groupSearchValue, setGroupSearchValue] = useState('');

  const [getGroupsList] = useLazyQuery(GET_GROUP_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIsEditMember(false);
      if (res?.getGroupsList?.group?.length < LIMIT) {
        setIsEnd(true);
      }
      if (groupSearchFlag) {
        setGroupsData([...res?.getGroupsList?.group]);
      } else {
        if (isEdit) {
          const tempBrands = [
            ...(record?.groupDetails || []),
            ...(res?.getGroupsList?.group || [])
          ];
          setGroupsData(
            uniqBy([...(groupsData || []), ...(tempBrands || [])], 'id')
          );
          return;
        }
        setGroupsData([
          ...(groupsData || []),
          ...(res?.getGroupsList?.group || [])
        ]);
      }
      setGroupLoading(false);
    },
    onError() {
      setGroupLoading(false);
    }
  });

  const [inviteMemberInTeam] = useMutation(INVITE_MEMBER_IN_TEAM, {
    onError() {
      setLoading(false);
    }
  });

  const [updateTeamMember] = useMutation(EDIT_MEMBER, {
    onError() {
      setLoading(false);
    }
  });

  const [deleteUser] = useMutation(DELETE_MEMBER, {
    onError() {
      setLoading(false);
    }
  });

  const [inviteGroupMember] = useMutation(INVITE_GROUP_MEMBER, {
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    getGroupsList({
      variables: {
        data: groupList
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (data) => {
    setLoading(true);
    setIsEdit(false);
    setIsListEnd(false);
    try {
      const inviteMemberInTeamResponse = await inviteMemberInTeam({
        variables: {
          teamId,
          data: map(data?.memberEmail, (item) => {
            return {
              email: item,
              role: data?.memberRole
            };
          })
        }
      });
      if (inviteMemberInTeamResponse?.data) {
        setLoading(true);
        handleVisible(true);
        if (data?.memberEmail && data?.memberGroups?.length > 0) {
          const addMemberInGroupResponse = await inviteGroupMember({
            variables: {
              email: data?.memberEmail,
              groupIds: data?.memberGroups
            }
          });
          try {
            if (addMemberInGroupResponse?.data) {
              handleVisible(false);
              setLoading(false);
            }
          } catch {
            setLoading(false);
            handleVisible(true);
          }
        } else {
          handleVisible(false);
          setLoading(false);
        }
        setListLoader(true);
        memberLists({
          variables: {
            data: {
              teamId,
              skip: 0,
              limit: LIMIT
            }
          }
        });
      }
    } catch {
      setLoading(false);
    }
  };

  const handleEditMember = async (data) => {
    setLoading(true);
    setIsListEnd(false);
    try {
      const updateMember = await updateTeamMember({
        variables: {
          memberId: record?.id,
          role: data?.role,
          groupIds: data?.groups
        }
      });
      if (updateMember?.data) {
        setLoading(false);
        setIsEdit(false);
        handleVisible(false);
        setListLoader(true);
        memberLists({
          variables: {
            data: {
              teamId,
              skip: 0,
              limit: LIMIT
            }
          }
        });
      }
    } catch {
      setLoading(false);
    }
  };

  const handleDeleteMember = async () => {
    setBtnLoading(true);
    try {
      const deleteUserInTeam = await deleteUser({
        variables: {
          teamId,
          memberId: record?.id
        }
      });
      if (deleteUserInTeam) {
        setLoading(false);
        setIsEdit(false);
        handleVisible(false);
        memberLists({
          variables: {
            data: {
              teamId,
              skip: 0,
              limit: LIMIT
            }
          }
        });
        setBtnLoading(false);
      }
    } catch (err) {
      setBtnLoading(false);
      return err;
    }
  };

  const handleResendInvitation = async (data) => {
    setIsResendInvitation(true);
    setIsListEnd(false);
    try {
      const inviteMemberInTeamResponse = await inviteMemberInTeam({
        variables: {
          teamId,
          data: {
            email: data?.invitedUserEmail,
            role: data?.role
          }
        }
      });
      if (inviteMemberInTeamResponse?.data) {
        setIsEdit(false);
        setListLoader(true);
        setIsResendInvitation(false);
        handleVisible(false);
        setLoading(false);
        memberLists({
          variables: {
            data: {
              teamId,
              skip: 0,
              limit: LIMIT
            }
          }
        });
      }
    } catch {
      setLoading(false);
    }
  };

  const onGroupScroll = (event) => {
    setGroupSearchFlag(false);
    if (groupScrollDebounceJob) {
      groupScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    groupScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 10;
      if (scrolledToBottom && !isEnd) {
        setGroupLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        getGroupsList({
          variables: {
            data: {
              teamId,
              skip: (debounceCall + 1) * LIMIT,
              limit: LIMIT,
              groupName: groupSearchValue
            }
          }
        });
      }
    }, 500);

    groupScrollDebounceJob();
  };

  const handleGroupBlur = () => {
    setGroupSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
    setGroupSearchFlag(true);
    getGroupsList({
      variables: {
        data: groupList
      }
    });
  };

  const handleGroupChange = (value) => {
    setGroupSearchValue(value);
    setGroupSearchFlag(true);
    setGroupLoading(true);
    getGroupsList({
      variables: {
        data: { ...groupList, groupName: value }
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGroupHandler = useCallback(
    debounce(handleGroupChange, 500),
    []
  );

  const handleGroupClear = () => {
    setGroupsData([]);
    getGroupsList({
      variables: {
        data: groupList
      }
    });
  };

  const handleCancel = () => {
    handleVisible(false);
    setIsEdit(false);
  };

  const handleDelete = () => {
    setDeleteMemberModalVisible(true);
  };

  return (
    <div>
      <Modal
        width={MODAL_WIDTH}
        title={isEdit ? 'Edit Member' : 'Add New Members'}
        centered
        destroyOnClose
        onCancel={handleCancel}
        wrapClassName="add-member-modal"
        visible={memberModal}
        footer={
          <>
            {AccessControl({
              allowedPermissions: ACTIONS?.ADD_MEMBER
            }) &&
              !isEdit && (
                <div>
                  <Button
                    className="invite-button"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={form?.submit}
                  >
                    Invite
                  </Button>
                </div>
              )}
            {AccessControl({
              allowedPermissions: ACTIONS?.UPDATE_TEAM_MEMBER
            }) &&
              isEdit &&
              record?.role !== ROLE_KEYS?.OWNER && (
                <div className="is-edit">
                  <div className="is-edit-child-div">
                    <img src={deleteIcon} alt="img" className="delete-icon" />
                    <span className="delete-user" onClick={handleDelete}>
                      Delete Member
                    </span>
                  </div>
                  <Button
                    className="save-btn"
                    type="primary"
                    htmlType="submit"
                    loading={loading || isResendInvitation}
                    onClick={form?.submit}
                  >
                    Save
                  </Button>
                </div>
              )}
          </>
        }
      >
        <Spin
          direction="horizontal"
          spinning={(isEdit && isEditMember) || isResendInvitation}
        >
          <Form
            preserve={false}
            form={form}
            name="addNewMembers"
            className="add-new-members"
            layout="vertical"
            onFinish={isEdit ? handleEditMember : handleSubmit}
            initialValues={initialValue}
          >
            <div className={isEdit ? 'edit-padding' : 'add-padding'}>
              {isEdit ? (
                <>
                  <Tabs className="member-tab">
                    <TabPane tab="Members" key="1">
                      <div className="d-flex flex-vertical">
                        <span className="font-12 line-16 mb-4">Invited By</span>
                        <div className="d-flex line-22 align-center mb-16">
                          <Avatar
                            size={19}
                            style={
                              !record?.invitedByDetails?.profileImage && {
                                backgroundColor: avatarColor(
                                  record?.invitedByDetails?.firstName
                                )
                              }
                            }
                            className="no-hover"
                            src={record?.invitedByDetails?.profileImage}
                          >
                            <span className="font-500 font-10 d-flex">
                              {record?.invitedByDetails?.firstName
                                ?.charAt(0)
                                ?.toUpperCase()}
                            </span>
                          </Avatar>
                          <span className="ml-4 line-22">
                            {trim(
                              `${record?.invitedByDetails?.firstName} ${record?.invitedByDetails?.lastName}`
                            )}
                          </span>
                        </div>
                      </div>
                      <Row gutter={8}>
                        {record?.firstName && (
                          <Col span={24}>
                            <Form.Item
                              label="Name"
                              className="group-list"
                              name="name"
                            >
                              <Input className="disabled-input" disabled />
                            </Form.Item>
                          </Col>
                        )}
                        <Col span={15}>
                          <Form.Item
                            label="Email Id"
                            className={`email-input ${
                              record?.userRegisterStatus === 'NOT_REGISTERED' &&
                              'mb-0'
                            }`}
                            name={isEdit ? 'email' : 'memberEmail'}
                          >
                            <Input
                              placeholder="Email, Comma Separated"
                              className=""
                              disabled={isEdit}
                            />
                          </Form.Item>
                          {record?.userRegisterStatus === 'NOT_REGISTERED' && (
                            <Text
                              name="resend_invitation"
                              onClick={() => handleResendInvitation(record)}
                              className="invitation d-block"
                            >
                              Resend invitation
                            </Text>
                          )}
                        </Col>
                        <Col span={9}>
                          <Form.Item
                            rules={[{ ...required }]}
                            label="Role"
                            name={isEdit ? 'role' : 'memberRole'}
                          >
                            <Select
                              placeholder="Select Role"
                              disabled={
                                !AccessControl({
                                  allowedPermissions:
                                    ACTIONS?.UPDATE_TEAM_MEMBER
                                }) ||
                                record?.role === ROLE_KEYS?.OWNER ||
                                record?.role === ROLE_KEYS?.ADMIN
                              }
                            >
                              <Select.Option value="OWNER" disabled>
                                Owner
                              </Select.Option>
                              <Select.Option value="ADMIN" disabled>
                                Admin
                              </Select.Option>
                              <Select.Option value="DEVELOPER">
                                Developer
                              </Select.Option>
                              <Select.Option value="TESTER">
                                Tester
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            rules={!isEdit && [{ ...required, type: 'array' }]}
                            label="Add to Group(s)"
                            className="group-list"
                            name={isEdit ? 'groups' : 'memberGroups'}
                          >
                            <Select
                              placeholder="Select Group(s)"
                              mode="multiple"
                              allowClear
                              disabled={
                                !AccessControl({
                                  allowedPermissions:
                                    ACTIONS?.UPDATE_TEAM_MEMBER
                                }) || record?.role === ROLE_KEYS?.OWNER
                              }
                              onBlur={handleGroupBlur}
                              onSearch={debouncedGroupHandler}
                              onClear={handleGroupClear}
                              filterOption={false}
                              notFoundContent={
                                groupLoading ? (
                                  <div className="d-flex justify-center align-center">
                                    <Spin />
                                  </div>
                                ) : (
                                  <Empty
                                    image={Empty?.PRESENTED_IMAGE_SIMPLE}
                                  />
                                )
                              }
                              onPopupScroll={onGroupScroll}
                            >
                              {map(groupsData, (detail) => {
                                return (
                                  <Option key={detail?.id}>
                                    {detail?.groupName}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Shared Apps" key="2">
                      <Row>
                        <Col
                          className="shared-app-row-div member-tab-div"
                          span={24}
                        >
                          {record?.applicationList?.length > 0 ? (
                            record?.applicationList?.map((details) => {
                              return (
                                <div
                                  className="shared-app-row"
                                  key={details?.id}
                                >
                                  <div className="width-percent-10 mr-4">
                                    <Avatar
                                      size={38}
                                      className="shared-app-avatar"
                                      key={details?.id}
                                      src={details?.appLogo || defaultIcon}
                                    />
                                  </div>
                                  <div className="width-percent-90">
                                    <span className="shared-app-name">
                                      {details?.appName}
                                    </span>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <Empty />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </>
              ) : (
                <Row gutter={8}>
                  <Col span={15}>
                    <Form.Item
                      rules={[required, checkEmailArray]}
                      label="Email Id(s)"
                      className="email-input"
                      name={isEdit ? 'email' : 'memberEmail'}
                    >
                      <Select
                        placeholder="Email, Comma Separated"
                        mode="tags"
                        tokenSeparators={[',']}
                        defaultOpen={false}
                        notFoundContent=""
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      rules={[{ ...required }]}
                      label="Role"
                      name={isEdit ? 'role' : 'memberRole'}
                    >
                      <Select placeholder="Select Role">
                        <Select.Option value="OWNER" disabled>
                          Owner
                        </Select.Option>
                        <Select.Option value="ADMIN">Admin</Select.Option>
                        <Select.Option value="DEVELOPER">
                          Developer
                        </Select.Option>
                        <Select.Option value="TESTER">Tester</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      rules={!isEdit && [{ type: 'array' }]}
                      label="Add to Group(s)"
                      className="group-list"
                      name={isEdit ? 'groups' : 'memberGroups'}
                    >
                      <Select
                        placeholder="Select Group(s)"
                        mode="multiple"
                        allowClear
                        disabled={isEdit}
                        filterOption={false}
                        onBlur={handleGroupBlur}
                        onSearch={debouncedGroupHandler}
                        onClear={handleGroupClear}
                        notFoundContent={
                          groupLoading ? (
                            <div className="d-flex justify-center align-center">
                              <Spin />
                            </div>
                          ) : (
                            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        onPopupScroll={onGroupScroll}
                      >
                        {map(groupsData, (detail) => {
                          return (
                            <Option key={detail?.id}>
                              {detail?.groupName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          </Form>
        </Spin>
      </Modal>
      <DeleteMemberModal
        deleteMemberModalVisible={deleteMemberModalVisible}
        setDeleteMemberModalVisible={setDeleteMemberModalVisible}
        record={record}
        handleDeleteMember={handleDeleteMember}
        loading={btnLoading}
      />
    </div>
  );
};

export default MembersModal;
